@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  padding-top: 40px;
}

.letter-spacing {

  letter-spacing: 0.1em;

}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4F46E5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.scroll-container {
  overflow-y: auto;
  white-space: normal;
}

.modal {
  width: 80%;
  max-width: 1800px;
  min-height: 400px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
}

.guidance-message {
  background-color: #f0f4ff;
  border-left: 5px solid #007bff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

.post-button {
  position: fixed;
  bottom: 40px;
  right: 20px;
  /* background-color: #ff9800;
  color: white; */
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1000;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.post-button:hover {
  background-color: #e68a00;
  transform: scale(1.05);
}

/* スマートフォン向けのスタイル（幅600px以下の画面） */
@media screen and (max-width: 600px) {
  .post-button {
    bottom: 10px;         /* 画面下から10pxに変更 */
    right: 10px;          /* 画面右から10pxに変更 */
    padding: 8px 16px;    /* パディングを少し小さく */
    font-size: 14px;      /* フォントサイズを小さく */
  }
}

/* タブレット向けのスタイル（幅600pxから900pxの画面） */
@media screen and (min-width: 601px) and (max-width: 900px) {
  .post-button {
    bottom: 15px;         /* 画面下から15pxに変更 */
    right: 15px;          /* 画面右から15pxに変更 */
    padding: 10px 18px;   /* パディングを少し小さく */
    font-size: 15px;      /* フォントサイズを少し小さく */
  }
}

@layer utilities {
  .hover-rise {
    @apply transition transform hover:-translate-y-1 hover:text-blue-500 duration-300;
  }
}


